<template>
  <div>
    <NavBar v-bind:page="page" />
    <Header img="/img/hosting.png" imgWidth="600px" v-bind:page="page" />
    <b-container fluid="true" class="layer2 mt-4">
      <b-container>
        <About v-bind:page="page" />
        <div class="row tariffs" id="tariffs">
          <div class="col-12 tariffs-header">
            <h1>{{ $t("hosting.header") }}</h1>
            <p>{{ $t("hosting.text") }}</p>
          </div>
          <template v-for="item in hosting">
            <div
              class="col-12 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="1000"
              :key="item.name"
            >
              <div class="tariff-card">
                <div class="text-center">
                  <font-awesome-icon class="tariff-icon" :icon="item.icon" />
                </div>
                <h2 class="text-center">
                  <strong>{{item.name}}</strong>
                </h2>
                <hr
                  class="my-3"
                  style="width: 50%; color: #ababab; height: 1px; background-color:#ababab;"
                />
                <h3 class="text-center">
                  {{item.price}}
                  <small>₽/мес</small>
                </h3>
                <b-row>
                  <b-col class="ml-5" style="padding-right:0px">
                    <p class="text-left" style="color: #8f96c7">
                      <font-awesome-icon
                        style="color: #4d6cfb; margin-right: 5px"
                        :icon="['fas', 'microchip']"
                      />Domains:
                    </p>
                    <p class="text-left" style="color: #8f96c7">
                      <font-awesome-icon
                        style="color: #4d6cfb; margin-right: 5px"
                        :icon="['fas', 'database']"
                      />Database:
                    </p>
                    <p class="text-left" style="color: #8f96c7">
                      <font-awesome-icon
                        style="color: #4d6cfb; margin-right: 5px"
                        :icon="['fas', 'hdd']"
                      />SSL:
                    </p>
                    <p class="text-left" style="color: #8f96c7">
                      <font-awesome-icon
                        style="color: #4d6cfb; margin-right: 5px"
                        :icon="['fas', 'network-wired']"
                      />Disk:
                    </p>
                    <p class="text-left" style="color: #8f96c7">
                      <font-awesome-icon
                        style="color: #4d6cfb; margin-right: 5px"
                        :icon="['fas', 'ethernet']"
                      />Dedicated IP:
                    </p>
                    <p class="text-left" style="color: #8f96c7">
                      <font-awesome-icon
                        style="color: #4d6cfb; margin-right: 5px"
                        :icon="['fas', 'flag']"
                      />Location:
                    </p>
                  </b-col>
                  <b-col style="padding-left:0px">
                    <p class="text-left" style="color: #8f96c7">{{item.domains}}</p>
                    <p class="text-left" style="color: #8f96c7">{{item.databases}}</p>
                    <p class="text-left" style="color: #8f96c7">Free</p>
                    <p class="text-left" style="color: #8f96c7">{{item.disk}}GB</p>
                    <p class="text-left" style="color: #8f96c7">{{item.d_ip}}</p>
                    <p class="text-left" style="color: #8f96c7">France</p>
                  </b-col>
                </b-row>
                <div class="text-center pb-5 pt-3 mx-5">
                  <b-button
                    block
                    v-bind:href="item.link"
                    variant="outline-primary"
                  >{{$t('hosting.btn_text')}}</b-button>
                </div>
              </div>
            </div>
          </template>
        </div>
        <FAQ v-bind:page="page" />
        <Footer />
      </b-container>
    </b-container>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import NavBar from "@/components/NavBar";
import Header from "@/components/Header";
import About from "@/components/About";
import FAQ from "@/components/FAQ";
import Footer from "@/components/Footer";

export default {
  name: "Landing",
  components: { NavBar, Header, About, FAQ, Footer },
  metaInfo: {
    title: "VM Haven — NVMe Хостинг для ваших сайтов",
    meta: [
      {
        name: "description",
        content:
          "VM Haven — NVMe Хостинг для ваших сайтов. Создаем бизнес вместе!",
      },
      {
        name: "keywords",
        content:
          "vmhaven, vm haven, хостинг vmhaven, хостинг vm haven, вм хевен, вмхевен, вм, хевен, хостинг, купить хостинг, хостинг сайтов, хостинг wordpress, хостинг для сайта",
      },
    ],
  },
  data() {
    return {
      page: "hosting",
      hosting: [
        {
          name: "SMALL",
          price: 99,
          domains: 1,
          databases: 1,
          disk: 1,
          d_ip: "2$",
          icon: ["fas", "bug"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvhost%26startform%3Dvhost%252Eorder%252Eparam%26pricelist%3D2%26period%3D1%26project%3D1",
        },
        {
          name: "MEDIUM",
          price: 249,
          domains: 5,
          databases: 1,
          disk: 5,
          d_ip: "2$",
          icon: ["fas", "qrcode"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvhost%26startform%3Dvhost%252Eorder%252Eparam%26pricelist%3D6%26period%3D1%26project%3D1",
        },
        {
          name: "LARGE",
          price: 559,
          domains: "Unlim.",
          databases: 1,
          disk: 20,
          d_ip: "Free",
          icon: ["fas", "terminal"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvhost%26startform%3Dvhost%252Eorder%252Eparam%26pricelist%3D39%26period%3D1%26project%3D1",
        },
      ],
    };
  },
  created() {
    AOS.init();
  },
};
</script>

<style scoped>
</style>
