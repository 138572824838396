<template>
  <b-row class="features-dock" id="features">
    <template v-for="(item, index) in $t('about.'+page)">
      <div class="col-12 col-lg-4 px-5 features" :key="index">
        <font-awesome-icon class="features-icon" :icon="item.icon" />
        <h2>{{item.header}}</h2>
        <p>{{item.text}}</p>
      </div>
    </template>
  </b-row>
</template>

<script>
export default {
  name: "About",
  props: {
    page: {require: true}
  }
};
</script>

<style scoped>
.layer2 .features {
  margin-bottom: 50px;
}

.layer2 .features-dock {
  padding-top: 96px;
}

.layer2 .features h2 {
  margin-top: 20px;
  font-size: 18px;
  color: white;
}

.layer2 .features p {
  /*padding-right: 70px;*/
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #848794;
}

.features-icon {
  width: 35px;
  height: 35px;
  color: #4d6cfb;
}
</style>