 <template>
  <div>
    <NavBar />
    <Header v-bind:page="page" img="/img/minecraft-1-logo-svg-vector.svg" imgWidth="400px" />
    <b-container fluid="true" class="layer2 mt-4">
      <b-container>
        <About v-bind:page="page" />
        <Plans />
        <FAQ v-bind:page="page" />
        <Footer />
      </b-container>
    </b-container>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import NavBar from "@/components/NavBar";
import Header from "@/components/Header";
import About from "@/components/About";
import Plans from "@/components/Plans";
import FAQ from "@/components/FAQ";
import Footer from "@/components/Footer";

export default {
  name: "Minecraft",
  components: { NavBar, Header, About, Plans, FAQ, Footer },
  metaInfo: {
    title: "VM Haven — Игровые VPS для Minecraft",
    meta: [
      {
        name: "description",
        content:
          "VM Haven — Игровые VPS для вашего проекта. Создаем бизнес вместе!",
      },
      {
        name: "keywords",
        content:
          "vmhaven, vm haven, хостинг vmhaven, хостинг vm haven, vps vmhaven, vds vmhaven, vps vm haven, vds vm haven, вм хевен, вмхевен, вм, хевен, купить вдс, вдс, впс, купить впс, vds, vps, купить vds, купить vps, купить сервер, виртуальный сервер, игровые vps, игровые vps, игровой сервер",
      },
    ],
  },
  created() {
    AOS.init();
  },
  data() {
    return {
      page: "minecraft",
    };
  },
};
</script>

<style scoped>
</style>