<template>
  <div class="row faq" id="faq">
    <div class="col-12">
      <h1 class="faq-title text-center">{{$t('faq.header')}}</h1>
      <p class="faq-description text-center">{{$t('faq.text')}}</p>
      <div class="accordion faq-accordion" role="tablist" style="outline: none">
        <template v-for="(item, index) in $t('faq.items.'+page)">
          <div class="card" v-b-toggle="'collapse-'+index" :key="index">
            <div class="card-header" id="index">
              <h5 class="mb-0">
                <b-btn
                  v-bind:data-target="'collapse-'+index"
                  v-bind:aria-controls="'collapse-'+index"
                  class="faq_btn collapsed text-white"
                  type="button"
                  data-toggle="collapse"
                  aria-expanded="true"
                >{{item.question}}</b-btn>
                <font-awesome-icon class="float-right faq-icon d-none d-lg-block" :icon="['fas', 'plus']" />
              </h5>
            </div>
            <b-collapse
              v-bind:id="'collapse-'+index"
              v-bind:aria-labelledby="'heading-'+index"
              visible
              accordion="faq_accordion"
              data-parent="#accordionExample"
            >
              <div class="card-body">{{item.answer}}</div>
            </b-collapse>
          </div>
        </template>
      </div>
      <p class="to_contact text-center my-3">
        <a
          href="https://wiki.vmhaven.com/"
          style="text-decoration:none; color:#9fb1d3"
          target="”_blank”"
        >
          <u>База знаний VM Haven</u>
        </a>
      </p>
      <p class="to_contact text-center my-3">
        {{$t('faq.end_text')}} -
        <a
          href="https://t.me/vmhaven_bot"
          style="text-decoration:none; color:#9fb1d3"
        >
          <u>{{$t('faq.end_text_link')}}</u>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  props: {
    page: { require: true },
  },
};
</script>

<style scoped>
.faq-description {
  margin-top: 15px;
  font-weight: 500;
  font-size: 17px;
  color: #9fb1d3;
}

.faq-accordion {
  margin-top: 110px;
}

.faq-accordion .card {
  background-color: #141933;
  border-radius: 25px !important;
  max-width: 651px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.faq-accordion .card-header {
  color: white;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.faq-accordion .card-header .btn-link {
  text-decoration: none;
}

.faq-icon {
  margin: 11px;
}

.faq-accordion .card {
  background: rgb(96, 111, 255);
  background: linear-gradient(
    135deg,
    rgba(96, 111, 255, 1) 0%,
    rgba(161, 170, 255, 1) 100%
  );
}

.faq-accordion .card-body {
  color: white;
  padding-top: 0px;
  margin-left: 12px;
  margin-right: 12px;
  /*background: rgb(96,111,255);*/
  /*background: linear-gradient(135deg, rgba(96,111,255,1) 0%, rgba(161,170,255,1) 100%);*/
}

.faq .to_contact {
  font-size: 17px;
  color: #9fb1d3;
}

/*костыли для прозрачной кнопки*/

.faq_btn {
  background-color: unset !important;
  border-color: unset !important;
  border-width: 0 !important;
}

.faq_btn:active {
  background-color: unset !important;
  border-color: unset !important;
  border-width: 0 !important;
}

.faq_btn:active:focus {
  box-shadow: unset !important;
}

.faq_btn:focus {
  box-shadow: unset !important;
}
</style>