<template>
  <b-container>
    <b-navbar toggleable="lg" class="navbar navbar-expand-lg navbar-dark landing-nav my-4">
      <div class="navbar-header">
        <router-link class="navbar-brand" :to="{ name:'Landing' }">
          <img height="50" width="175" class="" src="/img/logo_group.svg" alt />
        </router-link>
      </div>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <ul class="nav navbar-nav ml-auto">
          <li v-if="page === 'vps'" class="nav-item mx-1">
            <a
              class="nav-link text-white"
              href="#tariffs"
              v-scroll-to="'#tariffs'"
            >{{ $t("nav.vps") }}</a>
          </li>
          <li v-else class="nav-item mx-1">
            <router-link class="nav-link text-white" :to="{ name:'Landing' }">{{ $t("nav.vps") }}</router-link>
          </li>
          <li v-if="page === 'hosting'" class="nav-item mx-1">
            <a
              class="nav-link text-white"
              href="#tariffs"
              v-scroll-to="'#tariffs'"
            >{{ $t("nav.hosting") }}</a>
          </li>
          <li v-else class="nav-item mx-1">
            <router-link
              class="nav-link text-white"
              :to="{ name: 'Hosting'}"
            >{{ $t("nav.hosting") }}</router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link class="nav-link text-white" :to="{ name: 'Minecraft'}">Minecraft</router-link>
          </li>
          <li class="nav-item mx-1">
            <a class="nav-link text-white" href="#faq" v-scroll-to="'#faq'">FAQ</a>
          </li>
          <li class="nav-item mx-1">
            <a
              class="nav-link text-white"
              href="https://wiki.vmhaven.com/"
              target="”_blank”"
            >{{ $t("nav.wiki") }}</a>
          </li>
        </ul>
        <div class="nav navbar-nav ml-auto auth-link">
          <b-link class="nav-link login-link" href="https://bill.vmhaven.com/">{{ $t("nav.login") }}</b-link>
          <a class="nav-link text-white d-none d-lg-block">|</a>
          <b-link
            class="nav-link text-white"
            href="https://bill.vmhaven.com/billmgr?func=register"
          >{{ $t("nav.register") }}</b-link>
        </div>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>
<script>
export default {
  name: "NavBar",
  props: {
    page: { require: true },
  },
};
</script>

<style scoped>
.landing-nav {
  background-color: #16225e !important;
}

.auth-link .nav-link {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.login-link {
  color: #4d6cfb !important;
}
</style>