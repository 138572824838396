<template>
  <router-view></router-view>
</template>
<script>
import '@/assets/custom.scss'
export default {
  name: "App"
}
</script>

<style scoped>
</style>
