<template>
  <div>
    <NavBar v-bind:page="page" />
    <Header img="/img/header-img.webp" imgWidth="500px" v-bind:page="page" />
    <b-container fluid="true" class="layer2 mt-4">
      <b-container>
        <About v-bind:page="page" />
        <Trial />
        <Plans />
        <FAQ v-bind:page="page" />
        <Footer />
      </b-container>
    </b-container>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import NavBar from "@/components/NavBar";
import Header from "@/components/Header";
import About from "@/components/About";
import Plans from "@/components/Plans";
import FAQ from "@/components/FAQ";
import Footer from "@/components/Footer";
import Trial from "@/components/Trial";

export default {
  name: "Landing",
  components: { NavBar, Header, About, Plans, FAQ, Footer, Trial },
  metaInfo: {
    title: "VM Haven — Производительные VPS",
    meta: [
      {
        name: "description",
        content:
          "VM Haven — Производительные VPS для вашего сайта, игрового сервера или приложения. Создаем бизнес вместе!",
      },
      {
        name: "keywords",
        content:
          "vmhaven, vm haven, хостинг vmhaven, хостинг vm haven, vps vmhaven, vds vmhaven, vps vm haven, vds vm haven, вм хевен, вмхевен, вм, хевен, купить вдс, вдс, впс, купить впс, vds, vps, купить vds, купить vps, хостинг, купить хостинг, хостинг сайтов, купить сервер, виртуальный сервер",
      },
    ],
  },
  data() {
    return {
      page: "vps",
    };
  },
  created() {
    AOS.init();
  },
};
</script>

<style></style>

<style scoped>
</style>
