<template>
  <div class="row tariffs" id="tariffs">
    <div class="col-12 tariffs-header">
      <h1>{{ $t("vps.header") }}</h1>
      <p>{{ $t("vps.text") }}</p>
    </div>
    <div class="col-12">
      <div class="row mb-4">
        <div class="col-12 col-lg-4 my-2 px-5">
          <b-button
            :pressed="tabIndex == 0"
            variant="outline-primary"
            block
            @click="tabIndex = 0"
            class="text-white"
            >🇩🇪 Germany</b-button
          >
        </div>
        <div class="col-12 col-lg-4 my-2 px-5">
          <b-button
            :pressed="tabIndex == 1"
            disabled
            variant="outline-primary"
            block
            @click="tabIndex = 1"
            class="text-white"
            >🇩🇪 Germany 4.5GHZ</b-button
          >
        </div>
        <div class="col-12 col-lg-4 my-2 px-5">
          <b-button
            :pressed="tabIndex == 2"
            variant="outline-primary"
            block
            @click="tabIndex = 2"
            class="text-white"
            >🇫🇷 France 4.5GHZ</b-button
          >
        </div>
      </div>
      <div>
        <b-tabs v-model="tabIndex" nav-wrapper-class="d-none">
          <b-tab active
            ><div class="row">
              <template v-for="item in vps_germany">
                <div
                  class="col-12 col-lg-4 px-5"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  :key="item.name"
                >
                  <div class="tariff-card">
                    <div class="text-center">
                      <font-awesome-icon
                        class="tariff-icon"
                        :icon="item.icon"
                      />
                    </div>
                    <h2 class="text-center">
                      <strong>{{ item.name }}</strong>
                    </h2>
                    <hr
                      class="my-3"
                      style="
                        width: 50%;
                        color: #ababab;
                        height: 1px;
                        background-color: #ababab;
                      "
                    />
                    <h3 class="text-center">
                      {{ item.price }}
                      <small>₽/мес</small>
                    </h3>
                    <b-row>
                      <b-col class="ml-5" style="padding-right: 0px">
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'microchip']"
                          />CPU:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'memory']"
                          />RAM:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'hdd']"
                          />SSD:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'network-wired']"
                          />IPv4:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'ethernet']"
                          />Bandwith:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'shield-alt']"
                          />DDOS protection:
                        </p>
                      </b-col>
                      <b-col style="padding-left: 0px">
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.cpu }}
                          <small>4.0GHZ</small>
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.ram }}GB
                          <small>DDR4 3200</small>
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.ssd }}GB
                        </p>
                        <p class="text-left" style="color: #8f96c7">1</p>
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.bw }} Mbps
                        </p>
                        <p class="text-left" style="color: #8f96c7">L3/L4</p>
                      </b-col>
                    </b-row>
                    <div class="text-center pb-5 pt-3 mx-5">
                      <b-button
                        block
                        v-bind:href="item.link"
                        variant="outline-primary"
                        >{{ $t("vps.btn_text") }}</b-button
                      >
                    </div>
                  </div>
                </div>
              </template>
            </div></b-tab
          >
          <b-tab lazy
            ><p>None</p>
            <p></p
          ></b-tab>
          <b-tab lazy
            ><div class="row">
              <template v-for="item in vps_france_pro">
                <div
                  class="col-12 col-lg-4 px-5"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  :key="item.name"
                >
                  <div class="tariff-card">
                    <div class="text-center">
                      <font-awesome-icon
                        class="tariff-icon"
                        :icon="item.icon"
                      />
                    </div>
                    <h2 class="text-center">
                      <strong>{{ item.name }}</strong>
                    </h2>
                    <hr
                      class="my-3"
                      style="
                        width: 50%;
                        color: #ababab;
                        height: 1px;
                        background-color: #ababab;
                      "
                    />
                    <h3 class="text-center">
                      {{ item.price }}
                      <small>₽/мес</small>
                    </h3>
                    <b-row>
                      <b-col class="ml-5" style="padding-right: 0px">
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'microchip']"
                          />CPU:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'memory']"
                          />RAM:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'hdd']"
                          />SSD:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'network-wired']"
                          />IPv4:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'ethernet']"
                          />Bandwith:
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          <font-awesome-icon
                            style="color: #4d6cfb; margin-right: 5px"
                            :icon="['fas', 'shield-alt']"
                          />DDOS protection:
                        </p>
                      </b-col>
                      <b-col style="padding-left: 0px">
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.cpu }}
                          <small>4.5GHZ</small>
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.ram }}GB
                          <small>DDR4 3200</small>
                        </p>
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.ssd }}GB
                        </p>
                        <p class="text-left" style="color: #8f96c7">1</p>
                        <p class="text-left" style="color: #8f96c7">
                          {{ item.bw }} Mbps
                        </p>
                        <p class="text-left" style="color: #8f96c7">L3/L4</p>
                      </b-col>
                    </b-row>
                    <div class="text-center pb-5 pt-3 mx-5">
                      <b-button
                        block
                        v-bind:href="item.link"
                        variant="outline-primary"
                        >{{ $t("vps.btn_text") }}</b-button
                      >
                    </div>
                  </div>
                </div>
              </template>
            </div></b-tab
          >
        </b-tabs>
      </div>
      <div class="row"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Plans",
  data() {
    return {
      tabIndex: 1,
      vps_germany: [
        {
          name: "Minimal",
          price: 299,
          cpu: 1,
          ram: 2,
          ssd: 20,
          bw: 100,
          icon: ["fas", "bug"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D96%26period%3D1%26project%3D1",
        },
        {
          name: "Start",
          price: 849,
          cpu: 2,
          ram: 4,
          ssd: 60,
          bw: 500,
          icon: ["fas", "cube"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D106%26period%3D1%26project%3D1",
        },
        {
          name: "Standard",
          price: 1449,
          cpu: 4,
          ram: 8,
          ssd: 80,
          bw: 500,
          icon: ["fas", "briefcase"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D116%26period%3D1%26project%3D1",
        },
        {
          name: "Essential",
          price: 2349,
          cpu: 4,
          ram: 16,
          ssd: 90,
          bw: 1000,
          icon: ["fas", "code"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D126%26period%3D1%26project%3D1",
        },
        {
          name: "Pro",
          price: 2749,
          cpu: 5,
          ram: 20,
          ssd: 120,
          bw: 1000,
          icon: ["fas", "terminal"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D136%26period%3D1%26project%3D1",
        },
        {
          name: "Advanced",
          price: 3249,
          cpu: 6,
          ram: 24,
          ssd: 140,
          bw: 1000,
          icon: ["fas", "qrcode"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D146%26period%3D1%26project%3D1",
        },
      ],
      vps_france_pro: [
        {
          name: "Minimal",
          price: 499,
          cpu: 1,
          ram: 2,
          ssd: 20,
          bw: 100,
          icon: ["fas", "bug"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D174%26period%3D1%26project%3D1",
        },
        {
          name: "Start",
          price: 999,
          cpu: 2,
          ram: 4,
          ssd: 60,
          bw: 500,
          icon: ["fas", "cube"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D185%26period%3D1%26project%3D1",
        },
        {
          name: "Standard",
          price: 1999,
          cpu: 4,
          ram: 8,
          ssd: 80,
          bw: 500,
          icon: ["fas", "briefcase"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D195%26period%3D1%26project%3D1",
        },
        {
          name: "Essential",
          price: 3699,
          cpu: 4,
          ram: 16,
          ssd: 90,
          bw: 1000,
          icon: ["fas", "code"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D205%26period%3D1%26project%3D1",
        },
        {
          name: "Pro",
          price: 4499,
          cpu: 5,
          ram: 20,
          ssd: 120,
          bw: 1000,
          icon: ["fas", "terminal"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D215%26period%3D1%26project%3D1",
        },
        {
          name: "Advanced",
          price: 5199,
          cpu: 6,
          ram: 24,
          ssd: 140,
          bw: 1000,
          icon: ["fas", "qrcode"],
          link: "https://bill.vmhaven.com/billmgr?func=register&redirect=startpage%3Dvds%26startform%3Dvds%252Eorder%252Eparam%26pricelist%3D225%26period%3D1%26project%3D1",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
