<template>
  <b-container class="header pt-4">
    <b-row>
      <div class="col-12 col-lg-6 my-4">
        <h1 class="header-title">{{ $t("header."+page+".tagline") }}</h1>
        <p class="my-3">{{ $t("header."+page+".desc") }}</p>
        <b-button href="#tariffs" v-scroll-to="'#tariffs'" variant="primary" class="text-white my-4">
          {{ $t("header."+page+".button") }}
          <font-awesome-icon style="margin-left: 15px" :icon="['fas', 'arrow-right']" />
        </b-button>
      </div>
      <div class="col-6 d-none d-lg-block pt-4">
        <b-img fluid class="float-right" v-bind:width="imgWidth" v-bind:src="img" alt />
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Header",
  props: {
    page: { required: true },
    img: { required: true },
    imgWidth: { required: true, default: "500px" },
  },
};
</script>

<style scoped>
</style>