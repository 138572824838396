<template>
  <div class="row trial">
    <div
      class="col-12 p-4"
      style="background-color: #0b1030; border-radius: 15px"
    >
      <div class="row">
        <div class="col-12 col-lg-8">
          <h4>Триальный период <sup>NEW</sup></h4>
          <h5 class="pt-4">Зарегистрируйся и получи VPS сервер.*</h5>
          <h5 class="pb-4">
            Вместо тысячи слов протестируй сервер самостоятельно!
          </h5>
          <form
            action="https://bill.vmhaven.com/billmgr?func=register&email=fuck@you.pidor"
            method="GET"
          >
            <b-input-group class="mt-3" style="max-width: 400px">
              <b-form-input
                class="trial-email"
                type="email"
                name="email"
                placeholder="эл. почта"
              >
              </b-form-input>
              <b-form-input hidden name="func" value="register" placeholder="эл. почта">
              </b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary">Регистрация</b-button>
              </b-input-group-append>
            </b-input-group>
          </form>
          <p>
            *Стоимость сервера 50 рублей. Эта сумма учитывается при смене
            тарифа.
          </p>
        </div>
        <div class="col-4 d-none d-lg-block">
          <b-img fluid class="float-right" src="/img/trial.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Trial",
};
</script>

<style>
.trial p {
  margin-bottom: 5px;
  margin-top: 10px;
  color: rgb(143, 150, 199);
}
.trial h5 {
  margin-bottom: 5px;
  color: rgb(143, 150, 199);
}
.trial sup {
  margin-bottom: 5px;
  color: rgb(143, 150, 199);
  font-size: 70%;
}

.trial h4 {
  margin-top: 5px;
}
.trial-email {
  background-color: rgb(11, 16, 48);
  border: 1px solid #4d6cfb;
  color: white;
}
.trial-email:focus {
  background-color: #090d25;
  border: 1px solid #4d6cfb;
  color: white;
}
</style>
