<template>
  <div>
    <div class="row justify-content-center contacts" id="contacts">
      <div class="col-lg-4 col-12">
        <a href="https://vk.com/vmhaven" style="text-decoration:none">
          <div class="row">
            <div class="col-5">
              <div class="img-circle float-right">
                <font-awesome-icon class="contacts-icon-vk" :icon="['fab', 'vk']" />
              </div>
            </div>
            <div class="col-7">
              <p class="contacts-title">ВКонтакте</p>
              <p class="contacts-link">@vmhaven</p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-12 cursor-pointer">
        <a href="https://t.me/vmhaven_bot" style="text-decoration:none">
          <div class="row">
            <div class="col-5">
              <div class="img-circle float-right">
                <font-awesome-icon class="contacts-icon-tg" :icon="['fab', 'telegram-plane']" />
              </div>
            </div>
            <div class="col-7">
              <p class="contacts-title">{{$t("contacts.tech_support")}}</p>
              <p class="contacts-link">@vmhaven</p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-12">
        <div class="row">
          <div class="col-5">
            <div class="img-circle float-right">
              <font-awesome-icon class="contacts-icon-email" :icon="['fas', 'envelope']" />
            </div>
          </div>
          <div class="col-7">
            <p class="contacts-title">E-mail</p>
            <p
              class="contacts-link"
              style="
                    -webkit-user-select: text;
                    -khtml-user-select: text;
                    -moz-user-select: text;
                    -o-user-select: text;
                    user-select: text;
                  "
            >support@vmhaven.com</p>
          </div>
        </div>
      </div>
    </div>
    <footer class="row">
      <div class="col-12 d-flex">
        <p>© VMHaven.com 2022</p>
        <a class="ml-auto" href="https://docs.vmhaven.com/user-agreement.pdf">
          <u>Условия использования</u>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.contacts {
  margin-top: 90px;
  padding-bottom: 90px;
}

.img-circle {
  height: 60px;
  width: 60px;
  margin: 10px;
  border: 2px solid #4d6cfb;
  border-radius: 50%;
}

.contacts-icon-vk {
  width: 33px;
  height: 30px;
  margin-top: 15px;
  margin-left: 12px;
  color: white;
}

.contacts-icon-email {
  width: 30px;
  height: 30px;
  margin-top: 14px;
  margin-left: 14px;
  color: white;
}

.contacts-icon-tg {
  width: 30px;
  height: 30px;
  margin-top: 15px;
  margin-left: 12px;
  color: white;
}

.contacts-title {
  color: #717f9c;
  font-size: 16px;
  margin-bottom: 1px;
  margin-top: 15px;
}

.contacts-link {
  font-weight: 500;
  color: white;
  font-size: 16px;
  margin-bottom: 1px;
}

.contacts .col-5 {
  padding-right: 5px;
}

.contacts .col-7 {
  padding-left: 1px;
}

footer {
  padding-bottom: 45px;
}

footer p {
  font-weight: 500;
  color: #92a2c3;
  font-size: 16px;
}

footer a u {
  font-weight: 500;
  color: #92a2c3;
  font-size: 16px;
}
</style>