import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import Landing from "../views/Landing.vue";
import Minecraft from "../views/Minecraft.vue";
import Hosting from "../views/Hosting.vue";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/hosting",
    name: "Hosting",
    component: Hosting,
  },
  {
    path: "/vps/for/minecraft",
    name: "Minecraft",
    component: Minecraft,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
