import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import BootstrapVue from "bootstrap-vue";
import VueScrollTo from "vue-scrollto";
import VueI18n from "vue-i18n";
import VueMeta from "vue-meta";

Vue.use(BootstrapVue);
Vue.use(VueScrollTo);
Vue.use(VueI18n);
Vue.use(VueMeta);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faMicrochip,
  faMemory,
  faHdd,
  faNetworkWired,
  faEthernet,
  faShieldAlt,
  faShieldVirus,
  faDatabase,
  faPlus,
  faEnvelope,
  faBug,
  faCube,
  faUserSecret,
  faTags,
  faGhost,
  faBriefcase,
  faCode,
  faTerminal,
  faQrcode,
  faServer,
  faFlag,
  faInfinity,
  faArrowAltCircleDown,
  faBook,
  faGamepad,
  faLifeRing,
  faStopwatch,
  faMousePointer,
  faLock,
  faAt,
} from "@fortawesome/free-solid-svg-icons";
import { faVk, faTelegramPlane, faWindows } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faArrowRight,
  faMicrochip,
  faMemory,
  faHdd,
  faNetworkWired,
  faEthernet,
  faShieldAlt,
  faShieldVirus,
  faDatabase,
  faPlus,
  faEnvelope,
  faBug,
  faCube,
  faUserSecret,
  faTags,
  faGhost,
  faBriefcase,
  faCode,
  faTerminal,
  faQrcode,
  faServer,
  faFlag,
  faInfinity,
  faArrowAltCircleDown,
  faBook,
  faGamepad,
  faLifeRing,
  faStopwatch,
  faMousePointer,
  faLock,
  faAt
);

library.add(faVk, faTelegramPlane, faWindows);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  i18n,
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event("render-event"));
  },
});

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
